import React from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-reveal';
import Layout from '../../components/layout';
import agridiypic from '../../assets/images/agridiypic.png';

const Capstone = (props) => (
	<Layout>
		<Helmet>
			<title>Capstone</title>
			<meta name='description' content='Vertistacks Info' />
		</Helmet>
		<Fade duration={2500}>
			<div id='main' className='wrapper style1'>
				<div className='container'>
					<header className='major'>
						<h2>AgriDIY</h2>
						<p>
							SGP Food’s AgriDIY is an all-in-one product that aims to educate
							on food security, the crop cultivation process and familiarization
							of modern agriculture technology.
						</p>
						<img
							alt=''
							src={agridiypic}
							style={{ alignContent: `center` }}
						></img>
						<p> </p>
					</header>
					<div className='row gtr-150'>
						<div style={{ textAlign: `center` }}>
							<b>
								AgriDIY provides users with a hassle-free opportunity to
								cultivate their own plant crop via a user-friendly interface.
								Users can experience all stages of the incubation process with
								the use of the physical incubator whilst monitoring, recording,
								and cultivating the plant via our proprietary software program
								that integrates IoT and AR technology.
							</b>
						</div>
						<br />
						<div style={{ textAlign: `center` }}>
							<b>
								Our software program utilizes AR technology to present the user
								with detailed information and updates regarding their plant. It
								also utilizes IoT technology to allow users to alter the
								conditions of the incubator e.g., temperature, moisture,
								humidity, etc. via the software program’s interface.
							</b>
						</div>
						<br />
						<div style={{ textAlign: `center` }}>
							<b>
								We believe that AgriDIY packages the incubation process of a
								plant crop in a neat and convenient process, where it stands to
								give invaluable lessons and experience on agriculture. Given our
								current environmental climate, we believe that more and more
								people should be informed of these themes and we firmly believe
								that our product is able to deliver.
							</b>
						</div>
					</div>
				</div>
			</div>
		</Fade>
	</Layout>
);

export default Capstone;
